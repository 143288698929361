export default {
  // Colors
  textColor:"#2A2B2E",
  lightGrey:"#F5F5F5",
  lightprimary: "#e7f2fa",
  offerColor: "#B745FF",
  lightblue: "#e5f2fb",
  appliColor: "#d7263D",
  lightCheck: "rgba(47, 217, 183, 0.25)",
  TeamColor: "#2fd9b7",
  contactColor: "#0D1065",
}
