import React from "react";
import styled from "styled-components";
import contact from "../images/contact2.svg";
import plane from "../images/paper-plane.svg";
import variable from "../components/variables";
import background from "../images/contact.jpg";
import { Formik } from "formik";
import * as yup from "yup";
import Wrapper from "../components/wrapper";
import { contactInfo } from "../content/content.json";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";

class Contact extends React.Component {
  state = {
    submitted: false,
    loading: false,
    error: false,
  };

  render() {
    return (
      <Container background={background}>
        <Wrapper>
          <ContactContainer>
            <TitlePart>
              <img src={contact} alt="contact" /> <h2>Contactez nous</h2>
            </TitlePart>
            <ContactContent>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  sujet: "",
                  message: "",
                }}
                onSubmit={async (values, { resetForm }) => {
                  this.setState({ submitted: false, loading: true });
                  console.log("submit loading:" + this.state.loading);
                  fetch("https://formcarry.com/s/IT1vODpntt", {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                    },
                    body: JSON.stringify({
                      name: values.name,
                      email: values.email,
                      sujet: values.sujet,
                      message: values.message,
                    }),
                  })
                    .then(
                      resetForm({
                        name: "",
                        email: "",
                        sujet: "",
                        message: "",
                      }),
                      this.setState({ submitted: true, loading: false })
                    )
                    .catch(error =>
                      this.setState({ error: true, loading: false })
                    );
                }}
                validationSchema={yup.object().shape({
                  name: yup.string().required("Un nom est requis"),
                  email: yup
                    .string()
                    .email("L'email n'est pas valide")
                    .required("Un email est requis"),
                  sujet: yup.string().required("Un sujet est requis"),
                  message: yup.string().required("Un message est requis"),
                })}
                render={({
                  values,
                  errors,
                  touched,
                  handleSubmit,
                  handleChange,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="unit-field">
                      <label htmlFor="name">Nom :</label>
                      <input
                        id="name"
                        name="name"
                        type="text"
                        className="form-control"
                        value={values.name}
                        onChange={handleChange}
                      />
                      {errors.name && touched.name && (
                        <p className="error">{errors.name}</p>
                      )}
                    </div>
                    <div className="unit-field">
                      <label htmlFor="email">E-mail :</label>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="form-control"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && touched.email && (
                        <p className="error">{errors.email}</p>
                      )}
                    </div>
                    <div className="unit-field">
                      <label htmlFor="sujet">Sujet :</label>
                      <input
                        id="sujet"
                        name="sujet"
                        type="sujet"
                        value={values.sujet}
                        onChange={handleChange}
                      />
                      {errors.sujet && touched.sujet && (
                        <p className="error">{errors.sujet}</p>
                      )}
                    </div>
                    <div className="unit-field">
                      <label htmlFor="message">Message :</label>
                      <textarea
                        rows="3"
                        cols="33"
                        id="message"
                        name="message"
                        type="message"
                        value={values.message}
                        onChange={handleChange}
                      />
                      {errors.message && touched.message && (
                        <p className="error">{errors.message}</p>
                      )}
                    </div>
                    <ButtonContainer>
                      <button type="submit" value="send">
                        {" "}
                        {this.state.loading ? (
                          <ClipLoader
                            css={override}
                            sizeUnit={"px"}
                            size={20}
                            color={variable.lightSecondary}
                            loading={this.state.loading}
                          />
                        ) : (
                          <React.Fragment>Envoyer</React.Fragment>
                        )}
                      </button>
                    </ButtonContainer>
                    <Success
                      style={{
                        display: this.state.submitted ? "flex" : "none",
                      }}
                    >
                      <p>Super ! Votre message a bien été envoyé</p>
                    </Success>
                    <Error
                      style={{ display: this.state.error ? "flex" : "none" }}
                    >
                      <p>Mince, il y a eu un problème lors de l'envoi</p>
                    </Error>
                  </Form>
                )}
              />
              <Info>
                <p className="title">NOUS RENCONTRER</p>
                <p>{contactInfo.address}</p>
                <p className="title">NOUS APPELER</p>
                <p>{contactInfo.phone}</p>
              </Info>
            </ContactContent>
            <Plane>
              <img src={plane} alt="contact" />
              <img src={plane} alt="contact" />
            </Plane>
          </ContactContainer>
        </Wrapper>
      </Container>
    );
  }
}
const override = css`
  display: block;
  margin: 0 auto;
`;

const Container = styled.div`
  color: ${variable.textColor};
  padding: 20px;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  padding-top: 100px;
  padding-bottom: 70px;
  position: relative;
  @media screen and (max-width: 768px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 60px;
  }
  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    opacity: 0.825;
    height: 100%;
    width: 100%;
    background: #274156;
  }
`;

const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    margin-right: 15px;
    height: 22px;
  }
  h2 {
    font-weight: 600;
    text-transform: uppercase;
  }
`;
const ContactContent = styled.div`
  display: flex;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;
const ContactContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 40px 60px;
  @media screen and (max-width: 992px) {
    padding: 40px 30px;
  }
  background: #fff;
  border-radius: 10px;
  position: relative;
`;
const Plane = styled.div`
  position: absolute;
  top: 30px;
  right: 40px;
  img:first-child {
    height: 30px;
    margin-right: 15px;
  }
  img:last-child {
    height: 42px;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;
const Form = styled.form`
  width: 50%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 20px;
    order: 2;
  }
  .unit-field {
    margin-top: 10px;
  }
  label {
    font-weight: 500;
    font-family: "Germania One";
    font-size: 18px;
  }
  input,
  textarea {
    border-radius: 6px;
    border: none;
    outline: none;
    flex: 1;
    padding: 10px;
    line-height: 25px;
    width: 100%;

    background-color: ${variable.lightGrey};
  }
  input {
    height: 40px;
  }
  .error {
    opacity: 0.6;
    font-size: 0.95rem;
    margin-top: 2px;
    font-weight: 300;
    margin-left: 10px;
    margin-bottom: 0px;
  }
`;
const Info = styled.div`
  padding: 40px;
  width: 40%;
  color: #707070;
  .title {
    font-weight: bold;
  }

  @media screen and (max-width: 992px) {
    padding: 20px;
    width: 100%;
    padding: 0;
    padding-top: 30px;
  }
`;
const Success = styled.div`
transition:0,2s;
margin-top: 15px;
height:60px;
justify-content:center;
border-radius:5px;
align-items:center;
background ${variable.lightCheck};
color:#2fd9b7;
p {
  margin:0;
}
`;
const Error = styled.div`
  transition: 0, 2s;
  margin-top: 15px;
  height: 60px;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
  background: rgba(215, 38, 61, 0.2);
  color: ${variable.appliColor};
  p {
    margin: 0;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    display:flex;
    justify-content:center;
    align-items:center;
    font-size: 18px;
    margin-top: 25px;
    cursor: pointer;
    background: none;
    color: ${variable.contactColor};
    padding: 7px 40px;
    border: 1px solid ${variable.contactColor};
    border-radius: 5px;
    &:hover {
      background:${variable.contactColor}
      color:#fff;
    }
  }
`;

export default Contact;
