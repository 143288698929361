import React from "react";
import styled from "styled-components";
import conformity from "../images/conformity.svg";
import appliIcon from "../images/appli-red.svg";
import database from "../images/databasev3.svg";
import shieldIcon from "../images/shield.png";
import Wrapper from "../components/wrapper";
import "Devices.css/assets/devices.min.css";
import pattern from "../images/pattrn.png";
import variable from "../components/variables";
import { appliContent } from "../content/content.json";

const appli = () => (
  <Container pattern={pattern}>
    <Wrapper>
      <Conformity>
        <div className="circle circleContainer1" />

        <div className="circle circleContainer2" />

        <TitlePart>
          <img src={conformity} alt="conformité" />
          <h2>{appliContent.title1}</h2>
          <div className="circle circleTitle" />
        </TitlePart>

        <Content>
          <PhoneContainer>
            <div className="marvel-device iphone8 silver">
              <div className="top-bar" />
              <div className="sleep" />
              <div className="volume" />
              <div className="camera" />
              <div className="sensor" />
              <div className="speaker" />
              <div className="screen">
                <img
                  src="/screen/new_estimate.png"
                  alt="Création d'un nouveau devis"
                />
              </div>
              <div className="home" />
              <div className="bottom-bar" />
            </div>
          </PhoneContainer>
          <Text>
            <Surtitle>{appliContent.fistlineText1}</Surtitle>
            <MainText>{appliContent.mainText1}</MainText>
            <div className="circle  circleContent" />
            <Subtitle>{appliContent.lastlineText}</Subtitle>
          </Text>
        </Content>
      </Conformity>
      <Paper>
        <TitlePart>
          <img src={appliIcon} alt="appli" className="Appli" />
          <h2>{appliContent.title2}</h2>
        </TitlePart>
        <Content>
          <TextPaper>
            <Surtitle>{appliContent.fistlineText2}</Surtitle>
            <MainText>
              <p>{appliContent.mainText2}</p>
              <p>{appliContent.sentence}</p>
              <h5> {appliContent.subhead1} </h5>
              <p>{appliContent.textsub1}</p>
              <h5>{appliContent.subhead2}</h5>
              <p>{appliContent.textsub2}</p>
            </MainText>
          </TextPaper>
          <PhoneContainer>
            <div className="marvel-device iphone8 silver">
              <div className="top-bar" />
              <div className="sleep" />
              <div className="volume" />
              <div className="camera" />
              <div className="sensor" />
              <div className="speaker" />
              <div className="screen">
                <img src="/screen/signature.png" alt="Signature d'un devis" />
              </div>
              <div className="home" />
              <div className="bottom-bar" />
            </div>
            <div className="circle  circlePhone" />
          </PhoneContainer>
        </Content>
      </Paper>
      <Paper>
        <TitlePart>
          <img src={shieldIcon} alt="shield" className="shield" />
          <h2>{appliContent.title3}</h2>
        </TitlePart>
        <Content>
          <DatabaseContainer>
            <div className="circleData">
              <img src={database} className="database" alt="shield" />
            </div>
          </DatabaseContainer>
          <div className="datatextContainer">
            <TextPaper>
              <Surtitle>{appliContent.fistlineText3}</Surtitle>
              <MainText>
                <p>{appliContent.mainText3}</p>
                <p>
                  La solution stocke vos données personnelles et commerciales en
                  respectant la legislation RGPD.
                </p>
                <p>{appliContent.textsub3}</p>
              </MainText>
            </TextPaper>
          </div>
        </Content>
      </Paper>
    </Wrapper>
  </Container>
);

const Container = styled.div`
  color: ${variable.textColor};
  padding: 20px;
  padding-top: 50px;
  padding-bottom: 100px;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -2;
    height: 100%;
    opacity: 0.15;
    background: url(${props => props.pattern}) repeat-y;
    background-size: 100%;
  }
  .circle {
    position: absolute;
    border-radius: 50%;
    background: ${variable.lightblue};
    z-index: -1;
  }
  .circleContent {
    width: 480px;
    height: 480px;
    top: -80px;
    left: 150px;
  }
  .circleTitle {
    width: 80px;
    height: 80px;
    top: -40px;
    left: -30px;
  }
  .circleContainer1 {
    width: 70px;
    height: 70px;
    top: 20px;
    left: 70%;
  }
  .circleContainer2 {
    width: 130px;
    height: 130px;
    top: 80px;
    left: 78%;
  }
  .circlePhone {
    width: 420px;
    height: 420px;
    top: -60px;
    left: -55%;
  }
  @media screen and (max-width: 992px) {
    padding-bottom: 0;
    padding-top: 70px;
    .circle {
      display: none;
    }
  }
`;
const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  img {
    margin-right: 15px;
    width: 40px;
  }
  h2 {
    font-weight: 600;
    text-transform: uppercase;
  }
  .Appli {
    height: 40px;
  }
`;
const Conformity = styled.div`
  padding-top: 50px;
  position: relative;
`;
const Paper = styled.div`
  padding-bottom: 50px;
  @media screen and (max-width: 992px) {
    margin-top: 0;
  }
  .datatextContainer {
    display: flex;
    align-items: center;
  }
`;

const PhoneContainer = styled.div`
  position: relative;
  .marvel-device {
    transform: scale(0.6);
    margin-top: -180px;
    margin-bottom: -280px;
    z-index: 0;
    img {
      width: 100%;
      height: auto;
    }
    @media only screen and (max-width: 600px) {
      margin-top: -250px;
      transform: scale(0.4);
    }
    @media only screen and (max-width: 767px) {
      margin-top: -200px;
      transform: scale(0.5);
    }
  }
`;
const DatabaseContainer = styled.div`
  position: relative;
  .circleData {
    height: 420px;
    width: 420px;
    border-radius: 50%;
    background: ${variable.lightblue};
    z-index: -1;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 80px;
  }
  .database {
    height: 250px;
  }
  @media screen and (max-width: 992px) {
    .circleData {
      background: none;
      width: 100%;
      height: auto;
    }
    .database {
      margin-bottom: 50px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  font-size: 16px;
  position: relative;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;
const Text = styled.div`
  padding: 20px;
  padding-top: 50px;
  padding-left: 80px;
  @media screen and (max-width: 992px) {
    padding-left:0;
  flex-grow: 2;
  font-size: 18px;
  max-width: 800px;
  @media screen and (max-width: 992px) {
    width: 100%;
    font-size: 16px;
 
}
@media screen and (max-width: 600px) {
`;
const TextPaper = styled.div`
  padding-top: 20px;
  padding-right: 100px;
  @media screen and (max-width: 992px) {
    padding-right: 0;
  }
  flex-grow: 2;
  font-size: 18px;
  max-width: 800px;
  @media screen and (max-width: 992px) {
    font-size: 16px;
  }
`;
const Surtitle = styled.p`
  font-weight: 300;
  margin-bottom: 30px;
`;
const MainText = styled.div`
  margin-bottom: 30px;
`;
const Subtitle = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
`;
export default appli;
