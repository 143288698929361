import React from "react"
import styled from "styled-components"
import teamIcon from "../images/team-green.svg"
import Wrapper from "../components/wrapper"
import variable from "../components/variables"
import team from "../content/team.json"

const Team = () => (
  <Container>
    <Wrapper>
      <TitlePart>
        <img src={teamIcon} alt="team" /> <h2>Notre équipe</h2>
      </TitlePart>
      <Subtitle>Une équipe à votre service</Subtitle>
      <TeamContainer>
        {team.map((unit, index) => (
          <Unit key={`unit-${index + 1}`}>
            {unit.map(soldier => (
              <Soldier key={`team-${soldier.name}`}>
                <Photo>
                  <img src={`/${soldier.img}`} alt="Visuel métier" />
                </Photo>
                <h5>{soldier.name}</h5>
                <Job>{soldier.job}</Job>
              </Soldier>
            ))}
          </Unit>
        ))}
      </TeamContainer>
    </Wrapper>
  </Container>
)

const Container = styled.div`
  color: ${variable.textColor};
  padding: 20px;
  padding-top: 90px;
  padding-bottom: 60px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
`
const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    margin-right: 15px;
    height: 25px;
  }
  h2 {
    font-weight: 600;
    text-transform: uppercase;
  }
  h2,
  img {
    margin-bottom: 0;
  }
`
const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
`
const TeamContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`
const Unit = styled.div`
  margin: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
const Soldier = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    font-size: 20px;
  }
  padding: 20px;
`
const Photo = styled.div`
  height: 160px;
  width: 160px;
  border-radius: 50%;
  background: ${variable.lightGrey};
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100px;
    max-height: 100px;
  }
`
const Job = styled.p`
  color: #9b9b9b;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
`

export default Team
