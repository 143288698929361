import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = ({ children }) => <Container>{children}</Container>

const Container = styled.div`
  margin: 0 auto;
  max-width: 1260px;
  @media screen and (max-width: 1440px) {
    max-width: 1165px;
    padding: 0 20px 0 20px;
  }
  @media screen and (max-width: 1024px) {
    max-width: 950px;
    padding: 0 20px 0 20px;
  }
`
Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Wrapper
