import React from "react";
import styled from "styled-components";
import handShake from "../images/hand-shake.png";

import Wrapper from "./wrapper";
import variable from "./variables";

import partners from "../content/partners.json";

const Partner = () => (
  <Container>
    <Wrapper>
      <TitlePart>
        <img src={handShake} alt="handShake" /> <h2>Ils nous font confiance</h2>
      </TitlePart>
      <Subtitle>Quelques uns de nos partenaires</Subtitle>
      <PartnerContainer>
        {partners.map(partner => (
          <div
            className={`img-wrapper ${
              partner.isMain ? "main-partner" : "partner"
            }`}
            key={partner.name}
          >
            <img
              src={`/partners/${partner.img}`}
              alt={`Logo ${partner.name}`}
              title={partner.name}
            />
          </div>
        ))}
      </PartnerContainer>
    </Wrapper>
  </Container>
);

const Container = styled.div`
  color: ${variable.textColor};
  padding: 20px;
  padding-top: 70px;
  padding-bottom: 80px;
`;
const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  text-transform: uppercase;
  img {
    margin-right: 15px;
    height: 25px;
  }
  h2 {
    font-weight: 600;
  }
  h2,
  img {
    margin-bottom: 0;
  }
`;
const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
`;
const PartnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  img {
    max-height: 60px;
    max-width: 160px;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
  .img-wrapper {
    padding: 24px;
    display: none;
    @media screen and (min-width: 600px) {
      display: block;
    }
    &.main-partner {
      display: block;
    }
    @media screen and (max-width: 600px) {
      padding: 6px;
    }
    @media screen and (max-width: 992px) {
      padding: 12px;
    }
  }
`;

export default Partner;
