import casque from "../images/casque.svg";
import design from "../images/design.svg";
import responsive from "../images/responsive.svg";
import api from "../images/api.svg";
import security from "../images/security.svg";
import clock from "../images/clock.svg";

export const serviceContent = [
  {
    image: casque,
    title: "Support téléphonique",
    content: "Nos équipes de techniciens basées en France sont à votre écoute.",
  },
  {
    image: design,
    title: "Un design personnalisable",
    content:
      "Notre plateforme s’adapte à la charte graphique de votre entreprise.",
  },
  {
    image: responsive,
    title: "Une plateforme responsive",
    content:
      "KingKang s’adapte à tous vos outils numériques : smartphone, tablette, ordinateur.",
  },
  {
    image: security,
    title: "Vos données 100% françaises",
    content: "Nous vous garantissons la protection de vos données.",
  },
  {
    image: clock,
    title: "Service 24/7",
    content: "KingKang est utilisable à n’importe quel moment.",
  },
  {
    image: api,
    title: "API personnalisable",
    content: "Possibilité de disposer d’une api personnalisée.",
  },
];
