import React from "react";
import styled from "styled-components";

export default function footer() {
  return (
    <FooterWrapper>
      <p className="footer-header">
        <b>King</b>Kang est une entreprise propulsée par
      </p>
      <ImgWrapper>
        <img src="/boost/ad_normandie.png" alt="AD Normandie" />
        <img src="/boost/region_normandie.png" alt="Région Normandie" />
        <img src="/boost/ue.png" alt="Fond de solidarité" />
      </ImgWrapper>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.div`
  margin-top: -10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 15px 30px;
  background-color: #fff;
  .footer-header {
    font-weight: 300;
    margin: 0;
  }
`;

const ImgWrapper = styled.div`
  img {
    max-width: 80px;
    max-height: 80px;
    margin: 12px;
  }
`;
