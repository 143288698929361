import React from "react"
import styled from "styled-components"
import Option from "../images/option.svg"
import variable from "../components/variables"
import Wrapper from "../components/wrapper"
import { serviceContent } from "../content/serviceContent.js"

const Home = () => (
  <Container>
    <Wrapper>
      <TitlePart>
        <img src={Option} alt="portfeuille" /> <h2>NOS SERVICES</h2>
      </TitlePart>
      <CardContainer>
        {serviceContent.map((serviceItem, index) => {
          return (
            <Card key={serviceItem.title}>
              <IconContainer>
                <img src={serviceItem.image} alt="" />
              </IconContainer>
              <ContentContainer>
                <h5>{serviceItem.title}</h5>
                <p>{serviceItem.content}</p>
              </ContentContainer>
            </Card>
          )
        })}
      </CardContainer>
    </Wrapper>
  </Container>
)

const Container = styled.div`
  color: ${variable.textColor};
  padding: 20px;
  background: ${variable.lightGrey};
  padding-top: 100px;
  padding-bottom: 70px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
`

const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    margin-right: 15px;
    height: 25px;
  }
  h2 {
    font-weight: 600;
  }
  h2,
  img {
    margin-bottom: 0;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`
const Card = styled.div`
  background: #fff;
  max-width: 30%;
  padding: 30px;
  box-shadow: 0 0px 35px rgba(0, 0, 0, 0.05);
  @media screen and (max-width: 997px) {
    max-width: 45%;
  }
  @media screen and (max-width: 660px) {
    max-width: 100%;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
`

const IconContainer = styled.div`
  border-radius:50%;
  width:150px;
  height:150px
  border: 1px solid #C9C9C9;
  display:flex;
  justify-content:center;
 align-items:center;

 img {
   height:70px;
 }
`
const ContentContainer = styled.div`
  h5 {
    margin-top: 20px;
  }
  p {
    margin-top: 15px;
  }
`

export default Home
