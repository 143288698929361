import React from "react";
import Layout from "../components/layout";
import Home from "../components/home";
import Offers from "../components/offers";
import Team from "../components/team";
import Appli from "../components/appli";
import Partner from "../components/partner";
import Service from "../components/service";
import Contact from "../components/contact";
import SEO from "../components/seo";
import Footer from "../components/footer";

import { ScrollingProvider, Section } from "react-scroll-section";

const IndexPage = () => (
  <Layout>
    <ScrollingProvider scrollBehavior="smooth">
      <SEO
        title="KingKang"
        keywords={[
          `KingKang`,
          `application`,
          `bon de commande`,
          `dématérialisation`,
        ]}
        description="KingKang est une application qui permet de dématérialiser vos documents commerciaux, gérer un catalogue produits et des clients. En aposant une signature à valeur probatoire, les échanges deviennent sécurisés."
      />
      <Section id="home">
        <Home />
      </Section>
      <Section id="appli">
        <Appli />
      </Section>
      <Section id="service">
        <Service />
      </Section>

      <Section id="equipe">
        <Team />
      </Section>
      <Section id="offres">
        <Offers />
      </Section>

      <Partner />

      <Section id="contact">
        <Contact />
      </Section>
    </ScrollingProvider>
    <Footer />
  </Layout>
);

export default IndexPage;
