import React from "react";
import styled from "styled-components";
import YouTube from "react-youtube";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import classnames from "classnames";

import background from "../images/bg_style5.jpg";
import logo from "../images/logo-king-kang.svg";
import logoGrey from "../images/king-kang-logo-grey.svg";
import appli from "../images/appli.svg";
import appliGrey from "../images/appli-grey.svg";
import offer from "../images/offer.svg";
import offerGrey from "../images/offer-grey.svg";
import contact from "../images/contact.svg";
import contactGrey from "../images/contact-grey.svg";
import team from "../images/team.svg";
import teamGrey from "../images/team-grey.svg";
import pattern from "../images/pattrn.png";
import Wrapper from "../components/wrapper";
import { SectionLink } from "react-scroll-section";
import { home } from "../content/content.json";
import service from "../images/serviceBlue.svg";
import serviceGrey from "../images/serviceGrey.svg";

const MenuList = [
  {
    section: "appli",
    image: appli,
    imageScroll: appliGrey,
    name: "Application",
  },
  {
    section: "service",
    image: service,
    imageScroll: serviceGrey,
    name: "Services",
  },
  {
    section: "equipe",
    image: team,
    imageScroll: teamGrey,
    name: "Equipe",
  },
  {
    section: "offres",
    image: offer,
    imageScroll: offerGrey,
    name: "Offres",
  },

  {
    section: "contact",
    image: contact,
    imageScroll: contactGrey,
    name: "Contact",
  },
];

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      scroll100: false,
      displayMenu: false,
      isVideoReady: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  toggleClass = () => {
    const currentState = this.state.displayMenu;
    this.setState({ displayMenu: !currentState });
  };

  handleScroll() {
    if (typeof window !== "undefined") {
      if (window.scrollY > 100) {
        this.setState({ scroll100: true });
      } else {
        this.setState({ scroll100: false });
      }
    }
  }

  render() {
    const opts = {
      height: "100%",
      width: "100%",
      playerVars: {
        autoplay: 1,
        modestbranding: 0,
        rel: 0,
      },
    };
    const displayBgMenu = this.state.scroll100 || this.state.displayMenu;
    return (
      <Container background={background} pattern={pattern}>
        <Header
          className={classnames({
            HeaderScroll:
              this.state.scroll100 ||
              (this.state.displayMenu && !this.state.scroll100),
          })}
        >
          <Wrapper>
            <HeaderContent>
              <SectionLink section="home">
                {link => (
                  <Logo
                    onClick={link.onClick}
                    selected={link.isSelected}
                    className={classnames({
                      HeaderScrollLogo:
                        this.state.scroll100 && !this.state.displayMenu,
                    })}
                  >
                    <img
                      src={displayBgMenu ? logoGrey : logo}
                      alt="Logo KingKang"
                    />
                    <h5
                      style={{ color: displayBgMenu ? "#555555" : "#FFFFFF" }}
                    >
                      <span>King</span>Kang
                    </h5>
                  </Logo>
                )}
              </SectionLink>
              <Menu
                className={classnames({
                  menuMobile: this.state.displayMenu,
                  scrollmenu: displayBgMenu,
                })}
              >
                {MenuList.map((MenuListItem, index) => {
                  return (
                    <SectionLink
                      section={MenuListItem.section}
                      key={`menu-item-${index + 1}`}
                    >
                      {link => (
                        <div
                          onClick={e => {
                            this.setState({ displayMenu: false });
                            link.onClick(e);
                          }}
                          selected={link.isSelected}
                          className="menu-item"
                        >
                          <div className="menu-item-picto">
                            <img
                              src={
                                displayBgMenu
                                  ? link.isSelected
                                    ? MenuListItem.image
                                    : MenuListItem.imageScroll
                                  : MenuListItem.image
                              }
                              alt={`${MenuListItem.name} picto`}
                            />
                          </div>
                          <span className="menu-item-title">
                            {MenuListItem.name}
                          </span>
                        </div>
                      )}
                    </SectionLink>
                  );
                })}
              </Menu>
              <Burger
                onClick={this.toggleClass}
                className={classnames({
                  burgerScroll: displayBgMenu,
                })}
              >
                <span />
                <span />
                <span />
              </Burger>
            </HeaderContent>
          </Wrapper>
        </Header>
        <Wrapper>
          <VideoContainer>
            {!this.state.isVideoReady && (
              <Loader type="Puff" color="#007acb" height={50} width={50} />
            )}
            <div className="video-wrapper">
              <YouTube
                videoId="6l6E5F3rTgs"
                opts={opts}
                onReady={() => this.setState({ isVideoReady: true })}
                containerClassName="yt-wrapper"
              />
            </div>
          </VideoContainer>
          <ContentContainer>
            <HomeContent>
              <div className="surtitle">
                <h5>{home.subtitle}</h5>
              </div>
              <h1>{home.mainSentence}</h1>
              <p>{home.subText}</p>
              <SectionLink section="appli">
                {link => (
                  <CTA onClick={link.onClick} selected={link.isSelected}>
                    Découvrir
                  </CTA>
                )}
              </SectionLink>
            </HomeContent>
            <PhoneContainer>
              <div className="marvel-device ipad silver">
                <div className="camera" />
                <div className="screen">
                  <img src="/screen/home.png" alt="Accueil de KingKang" />
                </div>
                <div className="home" />
              </div>
            </PhoneContainer>
          </ContentContainer>
        </Wrapper>
      </Container>
    );
  }
}
const Container = styled.div`
  background-image: url(${props => props.background});
  object-fit: cover;
  position: relative;
  width: 100%;
  background-size: cover;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  &:before {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    opacity: 0.825;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: linear-gradient(#007acb, #0d1065);
    background: #274156;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -1;
    height: 100%;
    opacity: 0.15;
    background: url(${props => props.pattern}) repeat-y;
    background-size: 100%;
  }
  .HeaderScroll {
    background: #fff;
    box-shadow: 0 15px 35px 2px rgba(0, 0, 0, 0.07);
  }
`;
const Header = styled.header`
  position: fixed;
  z-index: 999;
  width: 100%;
`;
const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;
  color: #fff;
  .HeaderScrollLogo {
    flex-direction: row;
    justify-content: center;
    @media screen and (min-width: 766px) {
      img {
        height: 60px;
        margin: 0;
      }
      h5 {
        margin-left: 20px;
        color: #555;
        font-size: 16px;
      }
    }
  }
  .scrollmenu {
    .menu-item {
      color: #555;
    }
    .menu-item:hover {
      color: #dbdbdb;
    }
  }
  .burgerScroll {
    span {
      background: #555;
    }
  }
  .menuMobile {
    display: flex;
  }
`;
const Logo = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    height: 110px;
    margin-bottom: 10px;
  }
  h5 {
    font-weight: 300;
    text-transform: uppercase;
    span {
      font-weight: normal;
    }
  }
  @media screen and (max-width: 992px) {
    img {
      height: 90px;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      height: 80px;
    }
  }
`;
const Menu = styled.nav`
  @media screen and (max-width: 766px) {
    display: none;
    padding-right: 30px;
    padding-left: 5px;
    flex-direction: column;
    align-items: end;
  }
  margin: 0;
  display: flex;
  justify-content: space-around;
  padding-left: 50px;
  width: 100%;
  .menu-item {
    z-index: 5;
    display: flex;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s;
    img {
      height: 22px;
    }
    .menu-item-picto {
      width: 40px;
      display: inline-block;
      @media screen and (max-width: 766px) {
        order: 2;
        display: flex;
        justify-content: center;
        margin-left: 8px;
      }
    }
    .contactIcon {
      height: 17px;
    }
    @media screen and (max-width: 766px) {
      display: flex;
      margin-bottom: 8px;
      margin-top: 8px;
    }
  }
  @media screen and (max-width: 992px) {
    .menu-item {
      font-size: 16px;
    }
  }
  .menu-item:hover {
    color: #e5f2fb;
  }
`;
const Burger = styled.div`
  cursor: pointer;
  span {
    display: block;
    width: 30px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    background: #fff;
    border-radius: 1px;
  }
  span:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 766px) {
    display: none;
  }
`;
const PhoneContainer = styled.div`
  display: flex;
  flex-direction:column
  align-items: center;
  flex: 1;
  position: relative;
  padding-top:0;
  margin-top:-40px
  @media screen and (max-width: 767px) {
    margin-top:0
  }
  .marvel-device {
    margin-bottom:-150px;
    transform: scale(0.6);
    margin-top: -200px;
    z-index: 0;
    img {
      width: 100%;
      height: auto;
    }
    @media only screen and (max-width: 600px) {
      margin-top: -250px;
      transform: scale(0.4);
    }
    @media only screen and (max-width: 767px) {
      margin-top: -200px;
      transform: scale(0.5);
    }
  }
`;
const VideoContainer = styled.div`
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .video-wrapper {
    height: 405px;
    width: 720px;
    @media screen and (max-width: 767px) {
      height: 315px;
      width: 560px;
    }
    @media only screen and (max-width: 600px) {
      height: 225px;
      width: 400px;
    }
    @media only screen and (max-width: 400px) {
      height: 180px;
      width: 320px;
    }
    .yt-wrapper {
      height: 100%;
      width: 100%;
    }
  }
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  padding-top: 140px;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
const HomeContent = styled.div`
  z-index: 2;
  max-width: 50%;
  @media screen and (max-width: 600px) {
    max-width: 100%;
    margin-bottom: 30px;
  }
  color: #fff;
  .surtitle {
    display: inline-block;
    padding: 5px 10px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;
  }
  h1 {
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  h5 {
    font-weight: 300;
  }
`;
const CTA = styled.button`
  font-weight: bold;
  font-size: 18px;
  margin-top: 25px;
  cursor: pointer;
  color: #000
  padding: 7px 40px;
  border: none;
  border-radius: 5px;
  background: #fff;

  @media screen and (max-width: 600px) {
    display: none;
  }
`;
export default Home;
