import React, { Fragment } from "react";
import styled from "styled-components";
import { SectionLink } from "react-scroll-section";

import Portfeuille from "../images/portefeuille.svg";
import variable from "../components/variables";
import Wrapper from "../components/wrapper";
import tick from "../images/tick.svg";
import deleteIcon from "../images/delete.svg";
import features from "../content/features.json";
import offers from "../content/offers.json";

const COLUMN_WIDTH = 150;

const Offers = () => (
  <Container>
    <Wrapper>
      <TitlePart>
        <img src={Portfeuille} alt="portfeuille" /> <h2>Nos offres</h2>
      </TitlePart>
      <Subtitle>Une offre qui s'adapte à vos besoins</Subtitle>
      <div style={{ width: "100%", position: "relative" }}>
        <TableWrapper>
          <Table ctOffers={offers.length}>
            <thead>
              <tr>
                <th className="fixed-column" />
                <th className="fake-content" />
                {offers.map(item => (
                  <TableOfferHead key={item.name}>
                    <span className="offer-title">{item.name}</span>
                    <span className="offer-price">
                      <span>{item.data.price}</span>€ /mois
                    </span>
                  </TableOfferHead>
                ))}
              </tr>
            </thead>
            <tbody>
              <FeatureRow style={{ borderTop: 0 }}>
                <td className="fixed-column">Nombre de signatures par mois</td>
                <td className="fake-content">
                  <span>Nombre de signatures par mois</span>
                </td>
                {offers.map(item => (
                  <td style={{ textAlign: "center" }} key={item.name}>
                    {item.data.signaturesAMonth || "0"}
                  </td>
                ))}
              </FeatureRow>
              {features.map(featu => (
                <Fragment key={featu.label}>
                  <FeatureRow>
                    <td className="fixed-column">{featu.label}</td>
                    <td className="fake-content">
                      <span>{featu.label}</span>
                    </td>
                    {offers.map(item => (
                      <td key={item.name}>
                        <div className="feat-picto">
                          <span
                            style={{
                              background: item.features.includes(featu.key)
                                ? variable.lightCheck
                                : variable.lightGrey,
                            }}
                          >
                            <img
                              src={
                                item.features.includes(featu.key)
                                  ? tick
                                  : deleteIcon
                              }
                              alt={
                                item.features.includes(featu.key)
                                  ? "Inclus"
                                  : "Non inclus"
                              }
                            />
                          </span>
                        </div>
                      </td>
                    ))}
                  </FeatureRow>
                </Fragment>
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </div>
      <PersonnalOffer>
        <p>
          Vous souhaitez une{" "}
          <SectionLink section="contact">
            {link => (
              <span
                className="custom-offer"
                onClick={link.onClick}
                selected={link.isSelected}
              >
                Offre sur Mesure ?{" "}
              </span>
            )}
          </SectionLink>
          Contactez nous pour effectuer un devis
        </p>
      </PersonnalOffer>
    </Wrapper>
  </Container>
);

const Container = styled.div`
  color: ${variable.textColor};
  padding: 20px;
  background: ${variable.lightGrey};
  padding-top: 100px;
  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
`;
const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  img {
    margin-right: 15px;
    height: 25px;
  }
  h2 {
    font-weight: 600;
    text-transform: uppercase;
  }
  h2,
  img {
    margin-bottom: 0;
  }
`;
const Subtitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 20px;
`;
const TableWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: visible;
  padding-top: 15px;
`;
const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  min-width: ${props => props.ctOffers * COLUMN_WIDTH + 300}px;
  @media screen and (max-width: 992px) {
    min-width: ${props => props.ctOffers * COLUMN_WIDTH + 200}px;
  }
  @media screen and (max-width: 600px) {
    min-width: ${props => props.ctOffers * COLUMN_WIDTH + 130}px;
  }
  thead > tr > th {
    padding-bottom: 12px;
  }
  .fixed-column {
    position: absolute;
    left: 0;
    top: auto;
    background-color: #f5f5f5;
    border-top: 1px solid #e3e3e3;
    margin-top: -1px;
    padding-top: 8px;
    width: 300px;
    @media screen and (max-width: 992px) {
      width: 200px;
    }
    @media screen and (max-width: 600px) {
      width: 130px;
    }
  }
  tbody tr:first-child td.fixed-column {
    margin-top: -0.5px;
  }
  tbody tr:first-child td {
    border-top: 0;
  }
  th.fixed-column {
    height: 100%;
    border-top: 0;
  }
  .fake-content {
    width: 300px;
    @media screen and (max-width: 992px) {
      width: 200px;
    }
    @media screen and (max-width: 600px) {
      width: 130px;
    }
    span {
      visibility: hidden;
    }
  }
`;
const TableOfferHead = styled.th`
  width: ${COLUMN_WIDTH}px;
  text-align: center;
  .offer-title {
    font-size: 24px;
    text-transform: uppercase;
    display: block;
    font-weight: normal;
  }
  .offer-price {
    & > span {
      font-family: "Germania One";
      font-size: 28px;
    }
  }
`;
const FeatureRow = styled.tr`
  & > td {
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 1px solid #e3e3e3;
    .feat-picto {
      display: flex;
      flex: 2;
      justify-content: center;
      span {
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          height: 12px;
          user-select: text;
          pointer-events: none;
        }
      }
    }
  }
  border-top: 1px solid #e3e3e3;
`;
const PersonnalOffer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  p {
    padding: 15px 20px;
    border-radius: 10px;
    text-align: center;
    max-width: 400px;
    margin-bottom: 0;
    .custom-offer {
      color: ${variable.offerColor} !important;
      cursor: pointer;
    }
  }
`;

export default Offers;
